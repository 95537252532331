<template>
    <div class="m-5">
      <ErrorCard>
          <template v-slot:content>
                  <h1 class="card-title">We couldn't find this page</h1>
                  <div class="body-text clarity-margin-top-m">
                      We have been tidying up some of our webpages recently and you have found a missing link. Please report it <span class="report" @click="$router.push('/securemessaging/message'); contactUs();">here</span> to let us know or use the button below to return to your dashboard.
                  </div>
                  <div class="card-footer clarity-margin-top-m">
                      <Button class="clarity-btn clarity-gradient-orange" @click="$router.push('/');">Return to the Dashboard</Button>
                  </div>
          </template>
          <template v-slot:image>
              <img class="bottom" src="@/assets/img/404img.png" alt="ooops">
          </template>
      </ErrorCard>
    </div>
</template>
<script>
import ErrorCard from '@/components/common/ErrorCard'
import {useRoute} from "vue-router";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
    setup() {

        const route = useRoute()
        const store = useStore()

        const messageText = computed(() => {
            return 'I would like to report a missing page at "'+route.path+'"';
        })

        const contactUs = () => {
            store.dispatch('globalMessageTrigger',  {
                subject: 'Page not found - ' + route.path,
                category: 'Feedback',
                recipient: 'Admin Team',
                messagebody: messageText.value
            })
        }

        return {
            messageText,
            contactUs
        }
    },
    components: {
        ErrorCard,
    }
}
</script>
<style scoped>
.spacer {
    height: 40px;
    width: 100%;
}
.bottom {
    padding: 70px 30px 108px 60px;
}
.card-title {
    font: normal normal 300 60px Josefin Sans;
}
.report {
    color: var(--clarity-light-blue);
    text-decoration: underline;
    cursor: pointer;
}
@media screen and (max-width: 991px) {
    :deep(.card-title) {
        font-size: 32px !important;
        line-height: var(--clarity-line-spacing-32) !important;
    }
}
</style>
